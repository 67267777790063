import React, { useEffect, useState } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const mainUrl = "https://work.4-handy.com/api/application-forms";
const SubmitForm = ({ applicationFormId }) => {
  const [missingFields, setMissingFields] = useState([]);

  useEffect(() => {
    axios
      .get(`${mainUrl}/${applicationFormId}/get-missing-fields`)
      .then((response) => {
        setMissingFields(response.data);
      })
      .catch((error) => {
        toast.error("There was an error fetching the missing fields!");
      });
  }, [applicationFormId]);

  const validationSchema = Yup.object().shape(
    missingFields.reduce((schema, field) => {
      schema[field.field] = Yup.string().required("This field is required");
      return schema;
    }, {})
  );

  const handleSubmit = (values, { setSubmitting }) => {
    const payload = missingFields.map((field) => ({
      field: field.field,
      label: field.label,
      value: values[field.field],
    }));

    axios
      .put(`${mainUrl}/${applicationFormId}/update-missing-fields`, payload)
      .then((response) => {
        toast.success("Information updated successfully!");
        setSubmitting(false);
      })
      .catch((error) => {
        toast.error("There was an error updating the information!");
        setSubmitting(false);
      });
  };

  return (
    <div className="container mt-4">
      <Formik
        initialValues={missingFields.reduce((initialValues, field) => {
          initialValues[field.field] = "";
          return initialValues;
        }, {})}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="p-4 border rounded">
            {missingFields.map((field) => (
              <div key={field._id} className="mb-3 row">
                <label
                  htmlFor={field.field}
                  className="col-sm-2 col-form-label"
                >
                  {field.label}
                </label>
                <div className="col-sm-10">
                  <Field
                    type="text"
                    name={field.field}
                    className="form-control"
                  />
                  <ErrorMessage
                    name={field.field}
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            ))}
            <div className="text-center">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SubmitForm;
