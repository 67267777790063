import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import SubmitForm from "./SubmitForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <Router>
      <div className="App">
        <h1 className="text-center mt-4">
          Bổ sung thông tin cho hồ sơ tuyển dụng
        </h1>
        <Routes>
          <Route path="/" element={<MainPage />} />
        </Routes>
        <ToastContainer />
      </div>
    </Router>
  );
}

const MainPage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const applicationFormId = query.get("formId");
  let errorText = " Không có thông tin hồ sơ tương ứng, vui lòng kiểm tra lại";
  if (!applicationFormId) {
    toast.error(errorText);
    return <div className="text-center mt-4">{errorText}</div>;
  }

  return <SubmitForm applicationFormId={applicationFormId} />;
};

export default App;
